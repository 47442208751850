import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { onWheelPreventChangeNumberField } from 'utils/menu';
import LabelRequired from 'components/FormFields/LabelRequired';
import { FormControlLabel, FormGroup, Stack, Switch, TextField, Typography, Grid } from '@mui/material';

const MinAndMaxOptions = ({
  handleChangeMultipleOptions,
  canSelectMultipleOptions,
  minAnySingleModifierItems,
  labelProps,
  handleChangeField,
  formErrors,
  maxAnySingleModifierItems,
  minTotalNumberOfModifierItems,
  handleChangeFieldMinTotalNumberOfModifierItems,
  maxTotalNumberOfModifierItems,
  handleChangeFieldMaxTotalNumberOfModifierItems,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container alignItems="center">
        <Grid item xs={'auto'} mb={3}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  onChange={handleChangeMultipleOptions}
                  checked={canSelectMultipleOptions}
                  name="canSelectMultipleOptions"
                />
              }
              // disabled={true}
              labelPlacement="start"
              label={t('menu.can_select_multiple_options')}
              sx={{ '& .MuiFormControlLabel-label': { color: '#3D4350', fontWeight: 500, mr: 1.5 }, ml: 0 }}
            />
          </FormGroup>
        </Grid>
      </Grid>
      {canSelectMultipleOptions && (
        <>
          <Grid item xs={12} mb={3}>
            <Typography lineHeight="16px">{t('menu.single_modifier_desc')}</Typography>
          </Grid>
          <Stack direction="row" sx={{ height: 60 }}>
            <Grid item xs={6}>
              <TextField
                sx={{ width: '100%' }}
                label={<LabelRequired>{t('menu.min_any_single_modifier_items_label')}</LabelRequired>}
                onWheel={onWheelPreventChangeNumberField}
                name="minAnySingleModifierItems"
                size="small"
                value={minAnySingleModifierItems}
                InputLabelProps={labelProps}
                onChange={handleChangeField}
                error={formErrors?.minAnySingleModifierItems?.error}
                helperText={
                  formErrors?.minAnySingleModifierItems?.error
                    ? !Number(minAnySingleModifierItems)
                      ? `${t('menu.min_number')}`
                      : Number(minAnySingleModifierItems) > 100000
                      ? t('menu.max_number')
                      : `${t(formErrors?.minAnySingleModifierItems?.message?.split(' ')[0])} ${
                          formErrors?.minAnySingleModifierItems?.message?.split(' ')[1]
                        }`
                    : ''
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ ml: 1, width: '100%' }}
                label={<LabelRequired>{t('menu.max_any_single_modifier_items_label')}</LabelRequired>}
                onWheel={onWheelPreventChangeNumberField}
                name="maxAnySingleModifierItems"
                size="small"
                value={maxAnySingleModifierItems}
                InputLabelProps={labelProps}
                onChange={handleChangeField}
                error={formErrors?.maxAnySingleModifierItems?.error}
                helperText={
                  formErrors?.maxAnySingleModifierItems?.error
                    ? !Number(maxAnySingleModifierItems)
                      ? `${t('menu.min_number')}`
                      : Number(maxAnySingleModifierItems) > 100000
                      ? t('menu.max_number')
                      : `${t(formErrors?.maxAnySingleModifierItems?.message?.split(' ')[0])} ${
                          formErrors?.maxAnySingleModifierItems?.message?.split(' ')[1]
                        }`
                    : ''
                }
              />
            </Grid>
          </Stack>
          <Grid item xs={12} mb={3}>
            <Typography lineHeight="16px">{t('menu.total_number_modifier_desc')}</Typography>
          </Grid>
          <Stack direction="row" sx={{ height: 60 }}>
            <Grid item xs={6}>
              <TextField
                sx={{ width: '100%' }}
                label={<LabelRequired>{t('menu.min_total_number_modifier_label')}</LabelRequired>}
                onWheel={onWheelPreventChangeNumberField}
                name="minTotalNumberOfModifierItems"
                size="small"
                value={minTotalNumberOfModifierItems}
                InputLabelProps={labelProps}
                onChange={handleChangeFieldMinTotalNumberOfModifierItems}
                error={formErrors?.minTotalNumberOfModifierItems?.error}
                helperText={
                  formErrors?.minTotalNumberOfModifierItems?.error
                    ? !Number(minTotalNumberOfModifierItems)
                      ? `${t('menu.min_number')}`
                      : Number(minTotalNumberOfModifierItems) > 100000
                      ? t('menu.max_number')
                      : `${t(formErrors?.minTotalNumberOfModifierItems?.message?.split(' ')[0])} ${
                          formErrors?.minTotalNumberOfModifierItems?.message?.split(' ')[1]
                        }`
                    : ''
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                sx={{ ml: 1, width: '100%' }}
                label={<LabelRequired>{t('menu.max_total_number_modifier_label')}</LabelRequired>}
                onWheel={onWheelPreventChangeNumberField}
                name="maxTotalNumberOfModifierItems"
                size="small"
                value={maxTotalNumberOfModifierItems}
                InputLabelProps={labelProps}
                onChange={handleChangeFieldMaxTotalNumberOfModifierItems}
                error={formErrors?.maxTotalNumberOfModifierItems?.error}
                helperText={
                  formErrors?.maxTotalNumberOfModifierItems?.error
                    ? !Number(maxTotalNumberOfModifierItems)
                      ? `${t('menu.min_number')}`
                      : Number(maxTotalNumberOfModifierItems) > 100000
                      ? t('menu.max_number')
                      : `${t(formErrors?.maxTotalNumberOfModifierItems?.message?.split(' ')[0])} ${
                          formErrors?.maxTotalNumberOfModifierItems?.message?.split(' ')[1]
                        }`
                    : ''
                }
              />
            </Grid>
          </Stack>
        </>
      )}
    </>
  );
};

export default memo(MinAndMaxOptions);
